import React, { Component } from 'react'
import { MdWarning, MdDirectionsBike, MdLibraryBooks, MdBuild } from "react-icons/md";
import { IoIosArchive } from 'react-icons/io'
import { Link, StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

class Header extends Component {
  render() {
    return (
      <StaticQuery
        query={
          graphql`
            query {
              fileName: file(relativePath: { eq: "totalbike_sm.png" }) {
                childImageSharp {
                  fixed(width: 240) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
            }
        `}

        render={data => (
          <header className="container mx-auto">
            <div className="flex flex-col sm:flex-row justify-between items-center mx-auto pt-2 px-6 border-b border-gray-500 mb-5">
              <div className="flex items-center">
                <div className="sm:w-full md:mr-3">
                  <Link to="/" className="sm:m-auto" title="Home">
                    {data.fileName.childImageSharp?.fixed &&
                      <div
                        className="w-auto"
                      >
                        <Img fixed={
                          data.fileName.childImageSharp.fixed
                        } />
                      </div>
                    }
                  </Link>
                </div>
              </div>
              <div className="flex mt-4 sm:mt-0">
                <Link className="px-2 lg:px-4" to="/brands" title="Bicycle Brands">
                  <MdDirectionsBike className="lg:inline m-auto text-xl lg:mr-2" />
                  <span className="sm:text-sm">Brands</span>
                </Link>
                <Link className="px-2 lg:px-4" to="/articles" title="Articles">
                  <MdLibraryBooks className="lg:inline m-auto text-xl lg:mr-2" />
                  <span className="sm:text-sm">Articles</span>
                </Link>
                <Link className="px-2 lg:px-4" to="/repairs" title="Bicycle Repairs">
                  <MdBuild className="lg:inline m-auto text-xl lg:mr-2" />
                  <span className="sm:text-sm">Repairs</span>
                </Link>
                <Link className="px-2 lg:px-4" to="/recalls" title="Bicycle Recalls">
                  <MdWarning className="lg:inline m-auto text-xl lg:mr-2" />
                  <span className="sm:text-sm">Recalls</span>
                </Link>
                <Link className="px-2 lg:px-4" to="/archives" title="Archives">
                  <IoIosArchive className="lg:inline m-auto text-xl lg:mr-2" />
                  <span className="sm:text-sm">Archives</span>
                </Link>
              </div>
              <div className="hidden md:block">
              </div>
            </div>
          </header>
        )}
      />
    )
  }
}

export default Header
