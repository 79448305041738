import React from 'react'
import PropTypes from 'prop-types'
import Footer from './Footer'
import Header from './Header'
import Breadcrumb from './Breadcrumb'

const Layout = ({ children, location, nid }) => {
  return (
    <>
      <Header />
      {location.pathname !== '/' &&
      <Breadcrumb location={location} />
      }
      <main className="font-content">
        {children}
      </main>
      {location.hostname === 'localhost' && nid && 
        <div >
          <a href={`https://admin.totalbike.com/node/${nid}/edit`}
            rel="noopener noreferrer" target="_blank">
            Edit
          </a>
        </div>
      }
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.object,
  location: PropTypes.object,
  nid: PropTypes.string
};

export default Layout;
