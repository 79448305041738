import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const Breadcrumb = ({ location }) => {
  const pathTree = location.pathname.split('/')
  return (
    <div className="container mx-auto mb-4">
      <ul className="ml-6">
        <li className="inline mb-2 mt-0">
          <Link to="/" title="home">home</Link>
        </li>
        {pathTree[1] && pathTree[1] !== '404' &&
          <li className="inline mb-2 mt-0">
            <span className="mx-2">&raquo;</span>
            <Link to={'/' + pathTree[1]}
              title={pathTree[1].replace('-', ' ')}>
              {pathTree[1].replace('-', ' ')}
            </Link>
          </li>
        }
        {pathTree[3] &&
          <li className="inline mb-2 mt-0">
            <span className="mx-2">&raquo;</span>
          <Link
            to={'/' + pathTree[1] + '/' + pathTree[2]}
            title={pathTree[2].replace('-', ' ')}
            >
              {pathTree[2].replace('-', ' ')}
            </Link>
          </li>
        }
      </ul>
    </div>
  )
}

Breadcrumb.propTypes = {
  location: PropTypes.object
}

export default Breadcrumb
