import React from 'react';
import { Link } from "gatsby"

const Footer = () => (
  <footer className="container mx-auto py-10 mt-10 mb-8 clear">

    <div className="container">
      <div className="text-sm">
        <ul className="mt-1 list-none text-center">
          <li className="inline mr-5">
            <Link to="/contact" title="Contact Us">
              Contact Us
            </Link>
          </li>
          <li className="inline mr-5">
            <Link to="/terms" title="Terms & Conditions">
              Terms &amp; Conditions
            </Link>
          </li>
          <li className="inline mr-5">
            <Link to="/privacy-policy" title="Privacy Policy">
              Privacy Policy
            </Link>
          </li>
          <li className="inline mr-5">
            <Link to="/about" title="About Total Bike">
              About Total Bike
            </Link>
          </li>
        </ul>
      </div>
      <div className="text-center text-sm mt-4">
        Copyright 1999 - {new Date().getFullYear()} All Rights Reserved
      </div>
    </div>
  </footer>
);

export default Footer;
